var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "travio-center-container",
    { attrs: { "grid-width": "1/2" } },
    [
      _c("h2", { staticClass: "mb-4", staticStyle: { color: "#636363" } }, [
        _vm._v("Location Normalisation List"),
      ]),
      _c(
        "vx-card",
        [
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col w-full mt-4" },
              [
                _c(
                  "vs-button",
                  {
                    staticClass: "mr-4 sm:mb-0 mb-2",
                    on: { click: _vm.addLocationList },
                  },
                  [_vm._v("Add")]
                ),
                _c("vs-input", {
                  staticClass:
                    "float-right sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-2",
                  attrs: { placeholder: "Search..." },
                  on: { input: _vm.updateSearchQuery },
                  model: {
                    value: _vm.searchQuery,
                    callback: function ($$v) {
                      _vm.searchQuery = $$v
                    },
                    expression: "searchQuery",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("ag-grid-vue", {
            ref: "agGridTable",
            staticClass: "ag-theme-material w-100 my-4 ag-grid-table",
            attrs: {
              components: _vm.cellRendererComponents,
              columnDefs: _vm.columnDefs,
              defaultColDef: _vm.defaultColDef,
              rowData: _vm.locationLists,
              gridOptions: _vm.gridOptions,
              autoGroupColumnDef: _vm.autoGroupColumnDef,
              pagination: false,
              paginationPageSize: _vm.paginationPageSize,
              suppressPaginationPanel: true,
              context: _vm.context,
            },
            on: { "first-data-rendered": _vm.onFirstDataRendered },
          }),
          _c(
            "div",
            { staticClass: "flex flex-wrap justify-start mt-4" },
            [
              _c(
                "vs-button",
                {
                  staticClass: "mr-4 sm:mb-0 mb-2",
                  attrs: {
                    title: "Save newly added location normalisations",
                    disabled: _vm.newLocationList.length == 0,
                  },
                  on: { click: _vm.saveNewItems },
                },
                [_vm._v("Save All")]
              ),
              _c("div", { staticClass: "p-2" }, [
                _c(
                  "span",
                  { staticStyle: { color: "rgba(var(--vs-primary), 1)" } },
                  [_vm._v(_vm._s(_vm.pendingSaveVerbiage))]
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm.showEditor
        ? _c("location-normalisation-add-modal", {
            attrs: {
              locationNormalisation: _vm.currentLocationInEdit,
              countryCodes: _vm.countryCodes,
            },
            on: {
              onAdd: _vm.onNewItemAdded,
              onCancel: function ($event) {
                _vm.showEditor = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }